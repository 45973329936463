import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-controls-pagination-2',
  templateUrl: './pagination-2.component.html',
  styleUrls: ['./pagination-2.component.scss'],
})
export class VbControlsPagination2Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
