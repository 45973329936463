import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-wp-write',
  templateUrl: './wp-write.component.html',
  styleUrls: ['./wp-write.component.scss'],
})
export class VbAppPartialsWpWriteComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
