<h5 class="mb-4">
  <strong>Default Table</strong>
</h5>
<div class="mb-5">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Country</th>
        <th scope="col">Area</th>
        <th scope="col">Population</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let country of countries; index as i">
        <th scope="row">{{ i + 1 }}</th>
        <td>
          <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="mr-2"
            style="width: 20px">
          {{ country.name }}
        </td>
        <td>{{ country.area | number }}</td>
        <td>{{ country.population | number }}</td>
      </tr>
    </tbody>
  </table>
</div>
<h5 class="mb-4">
  <strong>Inverse Table</strong>
</h5>
<div class="mb-5">
  <table class="table table-dark">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Country</th>
        <th scope="col">Area</th>
        <th scope="col">Population</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let country of countries; index as i">
        <th scope="row">{{ i + 1 }}</th>
        <td>
          <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="mr-2"
            style="width: 20px">
          {{ country.name }}
        </td>
        <td>{{ country.area | number }}</td>
        <td>{{ country.population | number }}</td>
      </tr>
    </tbody>
  </table>
</div>