<div class="operate">
  <button nz-button [disabled]="numberOfChecked === 0" [nzType]="'primary'" [nzLoading]="isOperating"
    (click)="operateData()">
    Reload
  </button>
  <span *ngIf="numberOfChecked">Selected {{ numberOfChecked }} items</span>
</div>
<nz-table #rowSelectionTable nzShowPagination nzShowSizeChanger [nzData]="listOfAllData"
  (nzCurrentPageDataChange)="currentPageDataChange($event)" class="vb__utils__table mb-4">
  <thead>
    <tr>
      <th nzShowCheckbox [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
        (nzCheckedChange)="checkAll($event)"></th>
      <th>Name</th>
      <th>Age</th>
      <th>Address</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
      <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.id]" [nzDisabled]="data.disabled"
        (nzCheckedChange)="refreshStatus()"></td>
      <td>{{ data.name }}</td>
      <td>{{ data.age }}</td>
      <td>{{ data.address }}</td>
    </tr>
  </tbody>
</nz-table>