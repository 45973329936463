<div class="height-700 d-flex align-items-center">
  <nz-affix>
    <button nz-button [nzType]="'primary'">
      <span>Affix top</span>
    </button>
  </nz-affix>
  <br />
  <nz-affix nzOffsetBottom="0">
    <button nz-button [nzType]="'primary'">
      <span>Affix bottom</span>
    </button>
  </nz-affix>
</div>
