<ul class="list-unstyled mb-0">
  <li class="item">
    <a href="javascript:;" class="itemLink">
      <div class="itemCover mr-3">
        <img src="assets/images/content/hands.png" alt="Hands" />
      </div>
      <div>
        <div class="text-blue">New York Times</div>
        <div class="font-weight-bold mb-2">Bitcoin Costs $10k+</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry&apos;s
          standard dummy text ...
        </div>
      </div>
    </a>
  </li>
  <li class="item">
    <a href="javascript:;" class="itemLink">
      <div class="itemCover mr-3">
        <img src="assets/images/content/hands.png" alt="Hands" />
      </div>
      <div>
        <div class="text-blue">CNN</div>
        <div class="font-weight-bold mb-2">Bitcoin Costs $10k+</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry&apos;s
          standard dummy text ...
        </div>
      </div>
    </a>
  </li>
  <li class="item">
    <a href="javascript:;" class="itemLink">
      <div class="itemCover mr-3">
        <img src="assets/images/content/hands.png" alt="Hands" />
      </div>
      <div>
        <div class="text-blue">English Speakers</div>
        <div class="font-weight-bold mb-2">Bitcoin Costs $10k+</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry&apos;s
          standard dummy text ...
        </div>
      </div>
    </a>
  </li>
</ul>