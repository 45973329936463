import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-tables-bootstrap-2',
  templateUrl: './2.component.html',
  styleUrls: ['./2.component.scss'],
})
export class VbTablesBootstrap2Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
