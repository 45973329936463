<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
    <nz-avatar nzIcon="user" [nzShape]="'square'" [nzSize]="'large'" class="avatar"></nz-avatar>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <div>
          <strong>{{ 'topBar.profileMenu.email' | translate }}: </strong>
          {{userDate?.email || '—'}}
          <br/>
          <strong>{{ 'topBar.profileMenu.name' | translate }}: </strong>
          {{userDate?.name || '—'}}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a href="javascript: void(0);" (click)="logout()">
          <i class="fe fe-log-out mr-2"></i>
          {{ 'topBar.profileMenu.logout' | translate }}:
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
