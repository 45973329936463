import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-github-head-issue',
  templateUrl: './github-head-issue.component.html',
  styleUrls: ['./github-head-issue.component.scss'],
})
export class VbAppPartialsGithubHeadIssueComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
