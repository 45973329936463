<h5 class="mb-3">
  <strong>Basic Form Elements</strong>
</h5>
<form nz-form>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Fail</nz-form-label>
    <nz-form-control nzValidateStatus="error" [nzSpan]="12" nzErrorTip="Should be combination of numbers & alphabets">
      <input nz-input [ngModel]="'unavailable choice'" name="errorValid" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Warning</nz-form-label>
    <nz-form-control nzValidateStatus="warning" [nzSpan]="12">
      <input nz-input [ngModel]="'Warning'" name="warningValid" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Validating</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="validating" nzHasFeedback
      nzValidatingTip="I'm validating the content">
      <input nz-input [ngModel]="'The content is being validated'" name="validating" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Success</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="success" nzHasFeedback>
      <input nz-input [ngModel]="'The content'" name="successValid" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Warning</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="warning" nzHasFeedback
      nzWarningTip="Should be combination of numbers & alphabets">
      <input nz-input [ngModel]="'Warning'" name="warningHighValid" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Fail</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="error" nzHasFeedback
      nzErrorTip="Should be combination of numbers & alphabets">
      <input nz-input [ngModel]="'unavailable choice'" name="invalidValid" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Success</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="success" nzHasFeedback>
      <nz-date-picker name="date-picker-success"></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Warning</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="warning" nzHasFeedback>
      <nz-time-picker name="time-picker-warning"></nz-time-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Error</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="error" nzHasFeedback>
      <nz-select name="select-error" [ngModel]="'Option 1'">
        <nz-option nzValue="Option 1" nzLabel="Option 1"></nz-option>
        <nz-option nzValue="Option 2" nzLabel="Option 2"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Validating</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="validating" nzHasFeedback>
      <nz-select name="select-validate" [ngModel]="'Option 2'">
        <nz-option nzValue="Option 1" nzLabel="Option 1"></nz-option>
        <nz-option nzValue="Option 2" nzLabel="Option 2"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="5">Success</nz-form-label>
    <nz-form-control [nzSpan]="12" nzValidateStatus="success" nzHasFeedback>
      <nz-input-number name="inputnumber-success" style="width:100%"></nz-input-number>
    </nz-form-control>
  </nz-form-item>
</form>