<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu" (nzVisibleChange)="badgeCountIncrease()">
    <nz-badge [nzCount]="badgeCount">
      <i class="icon fe fe-bell"></i>
    </nz-badge>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="card vb__utils__shadow width-350">
      <div class="card-body p-0">
        <vb-widgets-lists-2></vb-widgets-lists-2>
      </div>
    </div>
  </nz-dropdown-menu>
</div>