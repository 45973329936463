import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-wp-post',
  templateUrl: './wp-post.component.html',
  styleUrls: ['./wp-post.component.scss'],
})
export class VbAppPartialsWpPostComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
