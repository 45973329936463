<h5 class="mb-4">
  <strong>Default Carousel</strong>
</h5>
<div class="mb-5">
  <ngb-carousel [showNavigationIndicators]="false">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="https://via.placeholder.com/1300x500/161537/161537" alt="Random first slide">
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="https://via.placeholder.com/1300x500/161537/161537" alt="Random second slide">
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="https://via.placeholder.com/1300x500/161537/161537" alt="Random third slide">
      </div>
    </ng-template>
  </ngb-carousel>
</div>
<h5 class="mb-4">
  <strong>With Indicators</strong>
</h5>
<ngb-carousel>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="https://via.placeholder.com/1300x500/161537/161537" alt="Random first slide">
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="https://via.placeholder.com/1300x500/161537/161537" alt="Random second slide">
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="https://via.placeholder.com/1300x500/161537/161537" alt="Random third slide">
    </div>
  </ng-template>
</ngb-carousel>