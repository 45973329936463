import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-3v1',
  templateUrl: './3v1.component.html',
})
export class VbGeneral3v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
