import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-app-partials-github-discuss',
  templateUrl: './github-discuss.component.html',
  styleUrls: ['./github-discuss.component.scss'],
})
export class VbAppPartialsGithubDiscussComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
