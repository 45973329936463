<div>
  <div class="card-body">
    <div class="font-weight-bold font-size-36 font-weight-bold text-success">
      12,255Gb
    </div>
  </div>
  <div class="position-relative">
    <x-chartist class="ct-chart height-200 ct-hidden-points" [data]="chartData" type="Line" [options]="chartOptions">
    </x-chartist>
  </div>
</div>