<div>
  <h5 class="mb-4"><strong>Default Layout</strong></h5>
  <div class="mb-5">
    <ul class="list-group">
      <li class="list-group-item">Cras justo odio</li>
      <li class="list-group-item">Dapibus ac facilisis in</li>
      <li class="list-group-item">Morbi leo risus</li>
      <li class="list-group-item">Porta ac consectetur ac</li>
      <li class="list-group-item">Vestibulum at eros</li>
    </ul>
  </div>
  <h5 class="mb-4"><strong>Anchors and buttons</strong></h5>
  <div class="mb-5">
    <div class="mb-5">
      <ul class="list-group">
        <a href="#" class="active list-group-item-action list-group-item">Cras justo odio</a>
        <a href="#" class="list-group-item-action list-group-item">Dapibus ac facilisis in</a>
        <a href="#" class="list-group-item-action list-group-item">Morbi leo risus</a>
        <a href="#" class="list-group-item-action list-group-item">Porta ac consectetur ac</a>
        <a href="#" class="disabled list-group-item-action list-group-item">Vestibulum at eros</a>
      </ul>
    </div>
    <ul class="list-group"><button class="active list-group-item-action list-group-item">Cras justo odio</button>
      <button class="list-group-item-action list-group-item">Dapibus ac facilisis in</button>
      <button class="list-group-item-action list-group-item">Morbi leo risus</button>
      <button class="list-group-item-action list-group-item">Porta ac consectetur ac</button>
      <button class="disabled list-group-item-action list-group-item">Vestibulum at eros</button>
    </ul>
  </div>
  <h5 class="mb-4"><strong>Horizontal</strong></h5>
  <div class="mb-5">
    <ul class="list-group list-group-horizontal-lg">
      <a href="#" class="list-group-item">Cras justo odio</a>
      <a href="#" class="list-group-item">Dapibus ac facilisis in</a>
      <a href="#" class="list-group-item">Morbi leo risus</a>
      <a href="#" class="list-group-item">Porta ac consectetur ac</a>
      <a href="#" class="list-group-item">Vestibulum at eros</a>
    </ul>
  </div>
</div>