<div class="row">
  <div class="col-lg-6 mb-5">
    <h5 class="mb-4">
      <strong>Default Buttons</strong>
    </h5>
    <button type="button" class="btn mr-2 mb-2">Clear</button>
    <button type="button" class="btn btn-default mr-2 mb-2">Default</button>
    <button type="button" class="btn btn-default mr-2 mb-2" disabled="">
      Default Disabled
    </button>
    <button type="button" class="btn btn-link mr-2 mb-2">Link</button>
    <br>
    <button type="button" class="btn btn-primary mr-2 mb-2">Primary</button>
    <button type="button" class="btn btn-secondary mr-2 mb-2">Secondary</button>
    <button type="button" class="btn btn-success mr-2 mb-2">Success</button>
    <button type="button" class="btn btn-danger mr-2 mb-2">Danger</button>
    <button type="button" class="btn btn-warning mr-2 mb-2">Warning</button>
    <button type="button" class="btn btn-info mr-2 mb-2">Info</button>
    <button type="button" class="btn btn-light mr-2 mb-2">Light</button>
    <button type="button" class="btn btn-dark mr-2 mb-2">Dark</button>
    <br>
    <button type="button" class="btn btn-outline-info mr-2 mb-2">Info</button>
    <button type="button" class="btn btn-outline-light mr-2 mb-2">Light</button>
    <button type="button" class="btn btn-outline-dark mr-2 mb-2">Dark</button>
    <button type="button" class="btn btn-outline-primary mr-2 mb-2">Primary</button>
    <button type="button" class="btn btn-outline-secondary mr-2 mb-2">Secondary</button>
    <button type="button" class="btn btn-outline-success mr-2 mb-2">Success</button>
    <button type="button" class="btn btn-outline-danger mr-2 mb-2">Danger</button>
    <button type="button" class="btn btn-outline-warning mr-2 mb-2">Warning</button>
  </div>
  <div class="col-lg-6 mb-5">
    <h5 class="mb-4">
      <strong>Sizing</strong>
    </h5>
    <button type="button" class="btn mr-2 mb-2">Normal</button>
    <button type="button" class="btn btn-default mr-2 mb-2">Normal</button>
    <button type="button" class="btn btn-default mr-2 mb-2" disabled="">Normal</button>
    <a href="javascript: void(0);" class="btn btn-link mr-2 mb-2">Normal</a>
    <br>
    <button type="button" class="btn btn-lg btn-primary mr-2 mb-2">Large</button>
    <button type="button" class="btn btn-secondary mr-2 mb-2">Normal</button>
    <button type="button" class="btn btn-lg btn-success mr-2 mb-2">Large</button>
    <button type="button" class="btn btn-warning mr-2 mb-2">Normal</button>
    <button type="button" class="btn btn-sm btn-danger mr-2 mb-2">Small</button>
    <br>
    <button type="button" class="btn btn-outline-info mr-2 mb-2">Noraml</button>
    <button type="button" class="btn btn-sm btn-outline-success mr-2 mb-2">Small</button>
    <button type="button" class="btn btn-lg btn-outline-primary mr-2 mb-2">Large</button>
    <button type="button" class="btn btn-outline-danger mr-2 mb-2">Normal</button>
  </div>
  <div class="col-lg-6 mb-5">
    <h5 class="mb-4">
      <strong>Squared</strong>
    </h5>
    <button type="button" class="btn btn-squared mr-2 mb-2">Clear</button>
    <button type="button" class="btn btn-squared btn-default mr-2 mb-2">Default</button>
    <button type="button" class="btn btn-squared btn-default mr-2 mb-2" disabled="">
      Default Disabled
    </button>
    <button type="button" class="btn btn-squared btn-link mr-2 mb-2">Link</button>
    <br>
    <button type="button" class="btn btn-squared btn-warning mr-2 mb-2">Warning</button>
    <button type="button" class="btn btn-squared btn-info mr-2 mb-2">Info</button>
    <button type="button" class="btn btn-squared btn-light mr-2 mb-2">Light</button>
    <button type="button" class="btn btn-squared btn-success mr-2 mb-2">Success</button>
    <button type="button" class="btn btn-squared btn-danger mr-2 mb-2">Danger</button>
    <button type="button" class="btn btn-squared btn-dark mr-2 mb-2">Dark</button>
    <button type="button" class="btn btn-squared btn-primary mr-2 mb-2">Primary</button>
    <button type="button" class="btn btn-squared btn-secondary mr-2 mb-2">Secondary</button>
    <br>
    <button type="button" class="btn btn-outline-dark mr-2 mb-2">Dark</button>
    <button type="button" class="btn btn-outline-success mr-2 mb-2">Success</button>
    <button type="button" class="btn btn-outline-danger mr-2 mb-2">Danger</button>
    <button type="button" class="btn btn-outline-warning mr-2 mb-2">Warning</button>
    <button type="button" class="btn btn-outline-primary mr-2 mb-2">Primary</button>
    <button type="button" class="btn btn-outline-secondary mr-2 mb-2">Secondary</button>
    <button type="button" class="btn btn-outline-info mr-2 mb-2">Info</button>
    <button type="button" class="btn btn-outline-light mr-2 mb-2">Light</button>
  </div>
  <div class="col-lg-6 mb-5">
    <h5 class="mb-4">
      <strong>Rounded</strong>
    </h5>
    <button type="button" class="btn btn-rounded mr-2 mb-2">Clear</button>
    <button type="button" class="btn btn-rounded btn-default mr-2 mb-2">Default</button>
    <button type="button" class="btn btn-rounded btn-default mr-2 mb-2" disabled="">
      Default Disabled
    </button>
    <button type="button" class="btn btn-rounded btn-link mr-2 mb-2">Link</button>
    <br>
    <button type="button" class="btn btn-rounded btn-primary mr-2 mb-2">Primary</button>
    <button type="button" class="btn btn-rounded btn-secondary mr-2 mb-2">Secondary</button>
    <button type="button" class="btn btn-rounded btn-success mr-2 mb-2">Success</button>
    <button type="button" class="btn btn-rounded btn-danger mr-2 mb-2">Danger</button>
    <button type="button" class="btn btn-rounded btn-dark mr-2 mb-2">Dark</button>
    <button type="button" class="btn btn-rounded btn-warning mr-2 mb-2">Warning</button>
    <button type="button" class="btn btn-rounded btn-info mr-2 mb-2">Info</button>
    <button type="button" class="btn btn-rounded btn-light mr-2 mb-2">Light</button>
    <br>
    <button type="button" class="btn btn-outline-warning mr-2 mb-2">Warning</button>
    <button type="button" class="btn btn-outline-primary mr-2 mb-2">Primary</button>
    <button type="button" class="btn btn-outline-secondary mr-2 mb-2">Secondary</button>
    <button type="button" class="btn btn-outline-info mr-2 mb-2">Info</button>
    <button type="button" class="btn btn-outline-light mr-2 mb-2">Light</button>
    <button type="button" class="btn btn-outline-dark mr-2 mb-2">Dark</button>
    <button type="button" class="btn btn-outline-success mr-2 mb-2">Success</button>
    <button type="button" class="btn btn-outline-danger mr-2 mb-2">Danger</button>
  </div>
  <div class="col-lg-6 mb-5">
    <h5 class="mb-4">
      <strong>Icon Buttons</strong>
    </h5>
    <div class="btn-group mr-2 mb-2" aria-label="" role="group">
      <button type="button" class="btn btn-success">
        <i class="fe fe-edit mr-1" aria-hidden="true"></i>
        Edit
      </button>
      <button type="button" class="btn btn-success">
        <i class="fe fe-send mr-1" aria-hidden="true"></i>
        Reply
      </button>
      <button type="button" class="btn btn-success">
        <i class="fe fe-share mr-1" aria-hidden="true"></i>
        Share
      </button>
    </div>
    <div class="btn-group mr-2 mb-2" aria-label="" role="group">
      <button type="button" class="btn btn-light">
        <i class="fe fe-edit mr-1" aria-hidden="true"></i>
        Edit
      </button>
      <button type="button" class="btn btn-light">
        <i class="fe fe-send mr-1" aria-hidden="true"></i>
        Reply
      </button>
      <button type="button" class="btn btn-light">
        <i class="fe fe-share mr-1" aria-hidden="true"></i>
        Share
      </button>
    </div>
    <br>
    <button type="button" class="btn btn-rounded btn-primary mr-2 mb-2">
      <i class="fe fe-activity"></i>
    </button>
    <button type="button" class="btn btn-rounded btn-secondary mr-2 mb-2">
      <i class="fe fe-alert-triangle"></i>
    </button>
    <button type="button" class="btn btn-rounded btn-success mr-2 mb-2">
      <i class="fe fe-anchor"></i>
    </button>
    <button type="button" class="btn btn-rounded btn-danger mr-2 mb-2">
      <i class="fe fe-award"></i>
    </button>
    <button type="button" class="btn btn-rounded btn-dark mr-2 mb-2">
      <i class="fe fe-battery"></i>
    </button>
    <button type="button" class="btn btn-rounded btn-warning mr-2 mb-2">
      <i class="fe fe-clock"></i>
    </button>
    <button type="button" class="btn btn-rounded btn-info mr-2 mb-2">
      <i class="fe fe-code"></i>
    </button>
    <button type="button" class="btn btn-rounded btn-light mr-2 mb-2">
      <i class="fe fe-credit-card"></i>
    </button>
  </div>
  <div class="col-lg-4 mb-3">
    <h5 class="mb-4">
      <strong>Block Buttons</strong>
    </h5>
    <button type="button" class="btn btn-rounded btn-primary btn-block mr-2 mb-2">
      <i class="fe fe-save mr-1"></i>
      Primary
    </button>
    <button type="button" class="btn btn-rounded btn-success btn-block mr-2 mb-2">
      Success
    </button>
    <button type="button" class="btn btn-rounded btn-block mr-2 mb-2">Clear</button>
  </div>
</div>