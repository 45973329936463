<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <button nz-button nzType="primary" nzPopoverTitle="Title" nzPopoverContent="Content" nzPopoverPlacement="topLeft"
    nz-popover class="mr-3 mb-3">
    Default Popover
  </button>
  <button nz-button nzPopoverTitle="Title" nzPopoverContent="Content" nzPopoverPlacement="topCenter" nz-popover
    class="mr-3 mb-3">
    Arrow points to center
  </button>
</div>