<div class="carousel rounded overflow-hidden">
  <nz-carousel>
    <div style="height: 150px;" class="height-150 pt-4 pl-4 overflow-hidden position-relative bg-light"
      nz-carousel-content>
      <h2 class="font-size-24 font-weight-bold">To Do</h2>
      <a class="font-size-18" href="#">Upgrade / Finish</a>
      <i class="icon fe fe-settings text-gray-4"></i>
    </div>
    <div style="height: 150px;" class="height-150 pt-4 pl-4 overflow-hidden position-relative bg-light"
      nz-carousel-content>
      <h2 class="font-size-24 font-weight-bold">Sales Growth</h2>
      <a class="font-size-18" href="#">View Report</a>
      <i class="icon fe fe-server text-gray-4"></i>
    </div>
  </nz-carousel>
</div>