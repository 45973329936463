<div>
  <div nz-dropdown nzTrigger="click" nzPlacement="bottomRight" class="dropdown" [nzDropdownMenu]="menu">
    <div class="flag">
      <img [src]="mapFlags[language]" [alt]="language" />
      <span class="text-uppercase">{{language}}</span>
    </div>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="changeLanguage('en-US')">
        <span class="menuIcon">
          <img [src]="mapFlags.en" alt="English" />
        </span>
        English
      </li>
      <li nz-menu-item (click)="changeLanguage('ar-EG')">
        <span class="menuIcon">
          <img [src]="mapFlags.ar" alt="Arabic" />
        </span>
        Arabic
      </li>
<!--      <li nz-menu-item (click)="changeLanguage('ar-SA')">-->
<!--        <span class="menuIcon">-->
<!--          <img [src]="mapFlags.eg" alt="English" />-->
<!--        </span>-->
<!--        French-->
<!--      </li>-->
<!--      <li nz-menu-item (click)="changeLanguage('ar-SA')">-->
<!--        <span class="menuIcon">-->
<!--          <img [src]="mapFlags.ua" alt="English" />-->
<!--        </span>-->
<!--        French-->
<!--      </li>-->
    </ul>
  </nz-dropdown-menu>
</div>
