<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-3">
  <nz-tag>Tag 1</nz-tag>
  <nz-tag>
    <a href="https://github.com/NG-ZORRO/ng-zorro-antd">Link</a>
  </nz-tag>
  <nz-tag nzMode="closeable" (nzOnClose)="onClose()">Tag 2</nz-tag>
  <nz-tag nzMode="closeable" (nzOnClose)="preventDefault($event)">Prevent Default</nz-tag>
</div>
<h5 class="mb-3">
  <strong>Presets</strong>
</h5>
<div class="mb-3">
  <nz-tag [nzColor]="'magenta'">magenta</nz-tag>
  <nz-tag [nzColor]="'red'">red</nz-tag>
  <nz-tag [nzColor]="'volcano'">volcano</nz-tag>
  <nz-tag [nzColor]="'orange'">orange</nz-tag>
  <nz-tag [nzColor]="'gold'">gold</nz-tag>
  <nz-tag [nzColor]="'lime'">lime</nz-tag>
  <nz-tag [nzColor]="'green'">green</nz-tag>
  <nz-tag [nzColor]="'cyan'">cyan</nz-tag>
  <nz-tag [nzColor]="'blue'">blue</nz-tag>
  <nz-tag [nzColor]="'geekblue'">geekblue</nz-tag>
  <nz-tag [nzColor]="'purple'">purple</nz-tag>
</div>
<h5 class="mb-3">
  <strong>Custom</strong>
</h5>
<div class="mb-3">
  <nz-tag [nzColor]="'#f50'">#f50</nz-tag>
  <nz-tag [nzColor]="'#2db7f5'">#2db7f5</nz-tag>
  <nz-tag [nzColor]="'#87d068'">#87d068</nz-tag>
  <nz-tag [nzColor]="'#108ee9'">#108ee9</nz-tag>
</div>