<nz-table #fixedTable [nzData]="listOfData" [nzScroll]="{ x: '1150px', y: '240px' }">
  <thead>
    <tr>
      <th nzLeft>Full Name</th>
      <th nzLeft>Age</th>
      <th>Column 1</th>
      <th>Column 2</th>
      <th>Column 3</th>
      <th>Column 4</th>
      <th>Column 5</th>
      <th>Column 6</th>
      <th>Column 7</th>
      <th>Column 8</th>
      <th nzRight>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of fixedTable.data">
      <td nzLeft>{{ data.name }}</td>
      <td nzLeft>{{ data.age }}</td>
      <td>{{ data.address }}</td>
      <td>{{ data.address }}</td>
      <td>{{ data.address }}</td>
      <td>{{ data.address }}</td>
      <td>{{ data.address }}</td>
      <td>{{ data.address }}</td>
      <td>{{ data.address }}</td>
      <td>{{ data.address }}</td>
      <td nzRight>
        <a>action</a>
      </td>
    </tr>
  </tbody>
</nz-table>