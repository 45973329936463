<div class="row">
  <div class="col-xl-6 offset-xl-3">
    <h3 class="text-block mt-5 mb-4 text-center">
      <strong>General Icons</strong>
    </h3>
    <ul class="vb__utils__iconPresent list-unstyled">
      <ul class="vb__utils__iconPresent">
        <li *ngFor="let icon of icons" nz-tooltip nzTooltipPlacement="top" [nzTooltipTitle]="icon">
          <i [class]="icon"></i>
        </li>
      </ul>
    </ul>
  </div>
</div>