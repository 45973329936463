import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-11v1',
  templateUrl: './11v1.component.html',
  styleUrls: ['./11v1.component.scss'],
})
export class VbGeneral11v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
