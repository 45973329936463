<div>
  <div class="mb-4">
    <nz-input-group [nzPrefix]="prefixTemplate">
      <input type="text" nz-input placeholder="Search lists..." />
    </nz-input-group>
    <ng-template #prefixTemplate><i nz-icon nzType="search"></i></ng-template>
  </div>
  <h5 class="mb-2">
    <strong>To Do Lists</strong>
  </h5>
  <div class="d-flex flex-column">
    <a *ngFor="let item of dialogs; let i = index;" [ngClass]="['item', i === activeIndex ? 'current' : '']"
      class="text-dark font-size-18" (click)="changeDialog(i)">
      <span class="text-truncate mr-1">{{ item.name }}</span>
    </a>
  </div>
</div>