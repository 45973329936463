<div class="row">
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Basic</strong>
    </h5>
    <div class="mb-5">
      <nz-radio-group [(ngModel)]="radioValue">
        <label nz-radio nzValue="A">A</label>
        <label nz-radio nzValue="B">B</label>
        <label nz-radio nzValue="C">C</label>
        <label nz-radio nzValue="D">D</label>
      </nz-radio-group>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Buttons</strong>
    </h5>
    <div class="mb-5">
      <nz-radio-group [(ngModel)]="radioValue">
        <label nz-radio-button nzValue="A">Hangzhou</label>
        <label nz-radio-button nzValue="B">Shanghai</label>
        <label nz-radio-button nzValue="C">Beijing</label>
        <label nz-radio-button nzValue="D">Chengdu</label>
      </nz-radio-group>
    </div>
  </div>
</div>