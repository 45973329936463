<div class="row">
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Default</strong>
    </h5>
    <div class="mb-5">
      <button nz-button nzType="primary" class="mr-3 mb-3">Primary</button>
      <button nz-button nzType="default" class="mr-3 mb-3">Default</button>
      <button nz-button nzType="dashed" class="mr-3 mb-3">Dashed</button>
      <button nz-button nzType="danger" class="mr-3 mb-3">Danger</button>
      <button nz-button nzType="link" class="mr-3 mb-3">Link</button>
    </div>
    <h5 class="mb-3">
      <strong>Size</strong>
    </h5>
    <div class="mb-5">
      <nz-radio-group [(ngModel)]="size">
        <label nz-radio-button nzValue="large">Large</label>
        <label nz-radio-button nzValue="default">Default</label>
        <label nz-radio-button nzValue="small">Small</label>
      </nz-radio-group>
      <br />
      <br />
      <button nz-button [nzSize]="size" nzType="primary" class="mr-3 mb-3">Primary</button>
      <button nz-button [nzSize]="size" nzType="default" class="mr-3 mb-3">Default</button>
      <button nz-button [nzSize]="size" nzType="dashed" class="mr-3 mb-3">Dashed</button>
      <button nz-button [nzSize]="size" nzType="danger" class="mr-3 mb-3">Danger</button>
      <button nz-button [nzSize]="size" nzType="link" class="mr-3 mb-3">Link</button>
      <br />
      <button nz-button nzType="primary" [nzSize]="size" class="mr-3 mb-3"><i nz-icon nzType="download"></i></button>
      <button nz-button nzType="primary" [nzSize]="size" nzShape="circle" class="mr-3 mb-3"><i nz-icon
          nzType="download"></i></button>
      <button nz-button nzType="primary" [nzSize]="size" nzShape="round" class="mr-3 mb-3"><i nz-icon
          nzType="download"></i></button>
      <button nz-button nzType="primary" [nzSize]="size" nzShape="round" class="mr-3 mb-3">
        <i nz-icon nzType="download"></i>Download
      </button>
      <button nz-button nzType="primary" [nzSize]="size" class="mr-3 mb-3"><i nz-icon
          nzType="download"></i>Download</button>
      <br />
      <nz-button-group [nzSize]="size">
        <button nz-button nzType="primary"><i nz-icon nzType="left"></i>Backward</button>
        <button nz-button nzType="primary">Forward<i nz-icon nzType="right"></i></button>
      </nz-button-group>
    </div>
    <h5 class="mb-3">
      <strong>Disabled</strong>
    </h5>
    <div class="mb-5">
      <button nz-button nzType="primary" class="mr-3 mb-3">Primary</button>
      <button nz-button nzType="primary" disabled class="mr-3 mb-3">Primary(disabled)</button>
      <br />
      <button nz-button nzType="default" class="mr-3 mb-3">Default</button>
      <button nz-button nzType="default" disabled class="mr-3 mb-3">Default(disabled)</button>
      <br />
      <button nz-button nzType="dashed" class="mr-3 mb-3">Dashed</button>
      <button nz-button nzType="dashed" disabled class="mr-3 mb-3">Dashed(disabled)</button>
      <br />
      <button nz-button nzType="link" class="mr-3 mb-3">Link</button>
      <button nz-button nzType="link" disabled class="mr-3 mb-3">Link(disabled)</button>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-3">
      <strong>Default</strong>
    </h5>
    <div class="mb-5">
      <button nz-button nzType="primary" nzShape="circle" class="mr-3 mb-3"><i nz-icon nzType="search"></i></button>
      <button nz-button nzType="primary" nzShape="circle" class="mr-3 mb-3">A</button>
      <button nz-button nzType="primary" class="mr-3 mb-3"><i nz-icon nzType="search"></i>Search</button>
      <button nz-button nzType="default" nzShape="circle" class="mr-3 mb-3"><i nz-icon nzType="search"></i></button>
      <button nz-button nzType="default" class="mr-3 mb-3"><i nz-icon nzType="search"></i>Search</button>
      <br />
      <button nz-button nzType="default" nzShape="circle" class="mr-3 mb-3"><i nz-icon nzType="search"></i></button>
      <button nz-button nzType="default" class="mr-3 mb-3"><i nz-icon nzType="search"></i>Search</button>
      <button nz-button nzType="dashed" nzShape="circle" class="mr-3 mb-3"><i nz-icon nzType="search"></i></button>
      <button nz-button nzType="dashed" class="mr-3 mb-3"><i nz-icon nzType="search"></i>Search</button>
    </div>
    <h5 class="mb-3">
      <strong>Loading</strong>
    </h5>
    <div class="mb-5">
      <button nz-button nzType="primary" nzLoading class="mr-3 mb-3"><i nz-icon nzType="poweroff"></i>Loading</button>
      <button nz-button nzType="primary" nzSize="small" nzLoading class="mr-3 mb-3">Loading</button>
      <br />
      <button nz-button nzLoading nzShape="circle" class="mr-3 mb-3"></button>
      <button nz-button nzLoading nzType="primary" nzShape="circle" class="mr-3 mb-3"></button>
    </div>
    <h5 class="mb-3">
      <strong>Block Buttons</strong>
    </h5>
    <div class="mb-5">
      <button nz-button nzType="primary" nzBlock class="mr-3 mb-3">Primary</button>
      <button nz-button nzType="default" nzBlock class="mr-3 mb-3">Default</button>
      <button nz-button nzType="dashed" nzBlock class="mr-3 mb-3">Dashed</button>
      <button nz-button nzType="danger" nzBlock class="mr-3 mb-3">Danger</button>
      <button nz-button nzType="link" nzBlock class="mr-3 mb-3">Link</button>
    </div>
  </div>
</div>