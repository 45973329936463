import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-3',
  templateUrl: './3.component.html',
})
export class VbGeneral3Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
