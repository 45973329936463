<div class="mb-5">
  <h5 class="mb-4">
    <strong>Basic Usage</strong>
  </h5>
  <div class="mb-5">
    <button class="btn btn-primary mb-3 mr-3" (click)="open(content)">Launch demo modal</button>
    <button class="btn btn-primary mb-3 mr-3" (click)="openCustomClass(vertical)">Vertically centered modal</button>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Profile update</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur.
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Do Something</button>
      </div>
    </ng-template>
    <ng-template #vertical let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Vertically centered modal</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur.
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Do Something</button>
      </div>
    </ng-template>
  </div>
</div>