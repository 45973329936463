import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-20v1',
  templateUrl: './20v1.component.html',
  styleUrls: ['./20v1.component.scss'],
})
export class VbGeneral20v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
