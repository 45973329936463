<div class="card-header-flex">
  <div class="d-flex flex-column justify-content-center mr-auto">
    <div class="d-flex flex-row justify-content-center">
      <h5 class="mb-0 mr-2">
        <i [ngClass]="data.icon" class="mr-2 font-size-18 text-muted"></i>
        <strong>{{ data.title }}</strong>
      </h5>
      <span class="text-muted">{{ data.description }}</span>
    </div>
  </div>
</div>