<ul class="list-unstyled mb-0">
  <li class="item">
    <div class="font-weight-bold mr-3 font-size-18">16:00</div>
    <div class="separator bg-success mr-3"></div>
    <div>
      <div>Payment Received</div>
      <div class="text-muted">From themeforest</div>
    </div>
  </li>
  <li class="item">
    <div class="font-weight-bold mr-3 font-size-18">15:28</div>
    <div class="separator bg-primary mr-3"></div>
    <div>
      <div>Payment Received</div>
      <div class="text-muted">From themeforest</div>
    </div>
  </li>
  <li class="item">
    <div class="font-weight-bold mr-3 font-size-18">14:26</div>
    <div class="separator mr-3"></div>
    <div>
      <div>Payment Received</div>
      <div class="text-muted">From Paypal</div>
    </div>
  </li>
  <li class="item">
    <div class="font-weight-bold mr-3 font-size-18">13:57</div>
    <div class="separator mr-3 bg-danger"></div>
    <div>
      <div>Payment Received</div>
      <div class="text-muted">From Bitcoin Address</div>
    </div>
  </li>
  <li class="item">
    <div class="font-weight-bold mr-3 font-size-18">13:22</div>
    <div class="separator bg-info mr-3"></div>
    <div>
      <div>Payment Received</div>
      <div class="text-muted">From themeforest</div>
    </div>
  </li>
  <li class="item">
    <div class="font-weight-bold mr-3 font-size-18">11:08</div>
    <div class="separator mr-3"></div>
    <div>
      <div>Payment Received</div>
      <div class="text-muted">From themeforest</div>
    </div>
  </li>
  <li class="item">
    <div class="font-weight-bold mr-3 font-size-18">10:01</div>
    <div class="separator mr-3"></div>
    <div>
      <div>Payment Received</div>
      <div class="text-muted">From themeforest</div>
    </div>
  </li>
</ul>