import { Component, AfterViewInit } from '@angular/core'
import c3 from 'c3'

@Component({
  selector: 'vb-charts-c3-5',
  templateUrl: './5.component.html',
  styleUrls: ['./5.component.scss'],
})
export class VbChartsC35Component implements AfterViewInit {
  constructor() {}
  ngAfterViewInit() {
    const colors = {
      primary: '#01a8fe',
      def: '#acb7bf',
      success: '#46be8a',
      danger: '#fb434a',
    }

    const scatter = c3.generate({
      bindto: '#scatter',
      data: {
        xs: {
          Danger: 'Danger_x',
          Primary: 'Primary_x',
        },
        columns: [
          [
            'Danger_x',
            3.5,
            3,
            3.2,
            3.1,
            3.6,
            3.9,
            3.4,
            3.4,
            2.9,
            3.1,
            3.7,
            3.4,
            3,
            3,
            4,
            4.2,
            3.9,
            3.5,
            3.8,
            3.8,
            3.4,
            3.7,
            3.6,
            3.3,
            3.4,
            3,
            3.4,
            3.5,
            3.4,
            3.2,
            3.1,
            3.4,
            4.1,
            4.2,
            3.1,
            3.2,
            3.5,
            3.6,
            3,
            3.4,
            3.5,
            2.3,
            3.2,
            3.5,
            3.8,
            3,
            3.8,
            3.2,
            3.7,
            3.3,
          ],
          [
            'Primary_x',
            3.2,
            3.2,
            3.1,
            2.3,
            2.8,
            2.8,
            3.3,
            2.4,
            2.9,
            2.7,
            2,
            3,
            2.2,
            2.9,
            2.9,
            3.1,
            3,
            2.7,
            2.2,
            2.5,
            3.2,
            2.8,
            2.5,
            2.8,
            2.9,
            3,
            2.8,
            3,
            2.9,
            2.6,
            2.4,
            2.4,
            2.7,
            2.7,
            3,
            3.4,
            3.1,
            2.3,
            3,
            2.5,
            2.6,
            3,
            2.6,
            2.3,
            2.7,
            3,
            2.9,
            2.9,
            2.5,
            2.8,
          ],
          [
            'Danger',
            0.2,
            0.2,
            0.2,
            0.2,
            0.2,
            0.4,
            0.3,
            0.2,
            0.2,
            0.1,
            0.2,
            0.2,
            0.1,
            0.1,
            0.2,
            0.4,
            0.4,
            0.3,
            0.3,
            0.3,
            0.2,
            0.4,
            0.2,
            0.5,
            0.2,
            0.2,
            0.4,
            0.2,
            0.2,
            0.2,
            0.2,
            0.4,
            0.1,
            0.2,
            0.2,
            0.2,
            0.2,
            0.1,
            0.2,
            0.2,
            0.3,
            0.3,
            0.2,
            0.6,
            0.4,
            0.3,
            0.2,
            0.2,
            0.2,
            0.2,
          ],
          [
            'Primary',
            1.4,
            1.5,
            1.5,
            1.3,
            1.5,
            1.3,
            1.6,
            1,
            1.3,
            1.4,
            1,
            1.5,
            1,
            1.4,
            1.3,
            1.4,
            1.5,
            1,
            1.5,
            1.1,
            1.6,
            1.3,
            1.5,
            1.2,
            1.3,
            1.4,
            1.4,
            1.2,
            1.5,
            1,
            1.1,
            1,
            1.2,
            1.6,
            1.5,
            1.6,
            1.5,
            1.3,
            1.3,
            1.3,
            1.2,
            1.4,
            1.2,
            1,
            1.3,
            1.2,
            1.3,
            1.3,
            1.1,
            1.3,
          ],
        ],
        type: 'scatter',
      },
      color: {
        pattern: [colors.danger, colors.primary],
      },
      axis: {
        x: {
          label: 'Width',
          tick: {
            outer: !1,
            fit: !1,
          },
        },
        size: {
          height: 400,
        },
        padding: {
          right: 40,
        },
        y: {
          label: 'Height',
          tick: {
            outer: !1,
            count: 5,
            values: [0, 0.4, 0.8, 1.2, 1.6],
          },
        },
      },
      grid: {
        x: {
          show: !1,
        },
        y: {
          show: !0,
        },
      },
    })
  }
}
