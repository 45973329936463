<h5 class="mb-3">
  <strong>Icons Usage</strong>
</h5>
<div class="mb-5">
  <i nz-icon [nzType]="'home'" class="mr-3 mb-3 font-size-24"></i>
  <i nz-icon [nzType]="'setting'" [nzTheme]="'fill'" class="mr-3 mb-3 font-size-24"></i>
  <i nz-icon [nzType]="'smile'" [nzTheme]="'outline'" class="mr-3 mb-3 font-size-24"></i>
  <i nz-icon [nzType]="'sync'" [nzSpin]="true" class="mr-3 mb-3 font-size-24"></i>
  <i nz-icon [nzType]="'smile'" [nzTheme]="'outline'" [nzRotate]="180" class="mr-3 mb-3 font-size-24"></i>
  <!-- Loading with new API would spin automatically! -->
  <i nz-icon [nzType]="'loading'" class="mr-3 mb-3 font-size-24"></i>
</div>