<div class="row">
  <div class="col-lg-6">
    <h5 class="mb-4">
      <strong>Custom Tabs</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset class="nav-tabs-line ">
        <ngb-tab title="Active">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Disabled" [disabled]="true">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4">
      <strong>No Bottom Border</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset class="nav-tabs-line nav-tabs-noborder">
        <ngb-tab title="Active">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Disabled" [disabled]="true">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4">
      <strong>Custom Tabs Bold</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset class="nav-tabs-line nav-tabs-line-bold">
        <ngb-tab title="Active">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Disabled" [disabled]="true">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4">
      <strong>Custom Tabs Bold with Icons</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset class="nav-tabs-line nav-tabs-line-bold">
        <ngb-tab>
          <ng-template ngbTabTitle>
            <i class="fe fe-activity mr-1"></i>
            Active
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <i class="fe fe-clock mr-1"></i>
            Link
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <i class="fe fe-trash mr-1"></i>
            Link
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab [disabled]="true">
          <ng-template ngbTabTitle>
            <i class="fe fe-plus-circle mr-1"></i>
            Disabled
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4">
      <strong>Default Tabs</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset>
        <ngb-tab title="Active">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Disabled" [disabled]="true">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4">
      <strong>Default Pills</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset type="pills">
        <ngb-tab title="Active">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Disabled" [disabled]="true">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4">
      <strong>Justified Pills</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset type="pills" justify="justified">
        <ngb-tab title="Active">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Disabled" [disabled]="true">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="col-lg-6">
    <h5 class="mb-4">
      <strong>Default Pills with Icons</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset type="pills">
        <ngb-tab>
          <ng-template ngbTabTitle>
            <i class="fe fe-activity mr-1"></i>
            Active
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <i class="fe fe-clock mr-1"></i>
            Link
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>
            <i class="fe fe-trash mr-1"></i>
            Link
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab [disabled]="true">
          <ng-template ngbTabTitle>
            <i class="fe fe-plus-circle mr-1"></i>
            Disabled
          </ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
  <div class="col-lg-12">
    <h5 class="mb-4">
      <strong>Vertical Pills</strong>
    </h5>
    <div class="mb-5">
      <ngb-tabset type="pills" orientation="vertical">
        <ngb-tab title="Active">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 1 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 2 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab>
          <ng-template ngbTabTitle>Link</ng-template>
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 3 Contents</div>
          </ng-template>
        </ngb-tab>
        <ngb-tab title="Disabled" [disabled]="true">
          <ng-template ngbTabContent>
            <div class="p-4 mt-4 border rounded">Tab 4 Contents</div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>