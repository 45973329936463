import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-hidden-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class VbHiddenGridComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
