import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-12v1',
  templateUrl: './12v1.component.html',
})
export class VbGeneral12v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
