<div class="topbar">
  <div class="mr-4">
<!--    <vb-topbar-fav-pages></vb-topbar-fav-pages>-->
  </div>
  <div class="mr-auto mr-md-1">
<!--    <vb-topbar-search></vb-topbar-search>-->
  </div>
  <div class="mr-4 d-none d-md-block">
<!--    <vb-topbar-issues-history></vb-topbar-issues-history>-->
  </div>
  <div class="mr-auto d-xl-block d-none">
<!--    <vb-topbar-project-management></vb-topbar-project-management>-->
  </div>

  <div class="mr-3 mt-2  d-sm-block">
<!--    <i *ngIf="settings.theme === 'default'" class="fe fe-moon" style="font-size: 18px" (click)="setTheme(settings.theme === 'default' ? 'dark' : 'default')"></i>-->
<!--    <i *ngIf="settings.theme !== 'default'" class="fe fe-sun" style="font-size: 18px" (click)="setTheme(settings.theme === 'default' ? 'dark' : 'default')"></i>-->
  </div>
  <div class="mr-3 d-none d-sm-block">
<!--    <vb-topbar-language-switcher></vb-topbar-language-switcher>-->
  </div>
  <div>
    <vb-topbar-user-menu></vb-topbar-user-menu>
  </div>
</div>
