<h5 class="mb-4">
  <strong>Smple Datepicker</strong>
</h5>
<div class="mb-5">
  <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next"></ngb-datepicker>
</div>
<div class="mb-5">
  <button class="btn btn-sm btn-outline-primary mr-2" (click)="selectToday()">Select Today</button>
  <button class="btn btn-sm btn-outline-primary mr-2" (click)="dp.navigateTo()">To current month</button>
  <button class="btn btn-sm btn-outline-primary mr-2" (click)="dp.navigateTo({year: 2013, month: 2})">To Feb
    2013</button>
  <br />
  <br />
  <pre>Month: {{ date.month }}.{{ date.year }}</pre>
  <pre>Model: {{ model | json }}</pre>
</div>