import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-lists-27',
  templateUrl: './27.component.html',
})
export class VbList27Component implements OnInit {
  options: any = {}
  current = [
    { name: 'Level 0', checked: true },
    { name: 'Level 0', checked: true },
    { name: 'Level 0', checked: true },
  ]

  constructor() {}
  ngOnInit() {}
}
