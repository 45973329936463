import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-hidden-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss'],
})
export class VbHiddenColorsComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
