import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-23v1',
  templateUrl: './23v1.component.html',
})
export class VbGeneral23v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
