import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-1v1',
  templateUrl: './1v1.component.html',
})
export class VbGeneral1v1Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
