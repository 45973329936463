<div class="d-flex flex-wrap align-items-center">
  <div class="mr-auto">
    <p class="text-uppercase font-weight-bold mb-1">
      Refunds
    </p>
    <p class="mb-0">
      All Time Orders
    </p>
  </div>
  <p class="font-weight-bold font-size-24 mb-0">
    +$8,412
  </p>
</div>
