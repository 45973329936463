<div>
  <h5 class="mb-4"><strong>Default Media Object</strong></h5>
  <div class="mb-5 media">
    <div class="media-left">
      <img src="https://via.placeholder.com/64x64/f0f2f4/f0f2f4" alt="Generic placeholder image"
        class="mr-4 media-object"></div>
    <div class="media-body">
      <h4 class="media-heading">Media heading</h4>
      Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
      scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce
      condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
    </div>
  </div>
  <div class="mt-3">
    <ul class="media-list">
      <li class="mb-5 media">
        <div class="media-left">
          <img src="https://via.placeholder.com/64x64/f0f2f4/f0f2f4" alt="Generic placeholder image"
            class="mr-4 media-object"></div>
        <div class="media-body">
          <h4 class="media-heading">Media heading</h4>
          Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
          scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis.
          Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.<div
            class="mb-5 media">
            <div class="media-left">
              <img src="https://via.placeholder.com/64x64/f0f2f4/f0f2f4" alt="Generic placeholder image"
                class="mr-4 media-object"></div>
            <div class="media-body">
              <h4 class="media-heading">Nested media heading</h4>
              Cras sit amet nibh libero, in gravida nulla. Nulla vel
              metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra
              turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.<div
                class="media">
                <div class="media-left">
                  <img src="https://via.placeholder.com/64x64/f0f2f4/f0f2f4" alt="Generic placeholder image"
                    class="mr-4 media-object"></div>
                <div class="media-body">
                  <h4 class="media-heading">Nested media heading</h4>
                  Cras sit amet nibh libero, in gravida nulla. Nulla
                  vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus
                  viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in
                  faucibus.
                </div>
              </div>
            </div>
          </div>
          <div class="media">
            <div class="media-left">
              <img src="https://via.placeholder.com/64x64/f0f2f4/f0f2f4" alt="Generic placeholder image"
                class="mr-4 media-object"></div>
            <div class="media-body">
              <h4 class="media-heading">Nested media heading</h4>
              Cras sit amet nibh libero, in gravida nulla. Nulla vel
              metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra
              turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
            </div>
          </div>
        </div>
      </li>
      <li class="media">
        <div class="media-body">
          <h4 class="media-heading">Media heading</h4>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
          scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis.
          Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
        </div>
        <div class="media-right"><img src="https://via.placeholder.com/64x64/f0f2f4/f0f2f4"
            alt="Generic placeholder image" class="ml-4 media-object"></div>
      </li>
    </ul>
  </div>
</div>