<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <nz-transfer [nzDataSource]="list" [nzDisabled]="disabled" [nzTitles]="['Source', 'Target']"
    (nzSelectChange)="select($event)" (nzChange)="change($event)">
  </nz-transfer>
  <div style="margin-top: 16px;">
    <nz-switch [(ngModel)]="disabled" nzCheckedChildren="disabled" nzUnCheckedChildren="disabled"></nz-switch>
  </div>
</div>