import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-lists-13',
  templateUrl: './13.component.html',
  styleUrls: ['./13.component.scss'],
})
export class VbList13Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
