<h5 class="mb-3">
  <strong>Horizontal</strong>
</h5>
<div class="mb-5">
  <ul nz-menu nzMode="horizontal">
    <li nz-menu-item nzSelected>
      <i nz-icon nzType="mail"></i>
      Navigation One
    </li>
    <li nz-menu-item nzDisabled>
      <i nz-icon nzType="appstore"></i>
      Navigation Two
    </li>
    <li nz-submenu nzTitle="Navigation Three - Submenu" nzIcon="setting">
      <ul>
        <li nz-menu-group nzTitle="Item 1">
          <ul>
            <li nz-menu-item>Option 1</li>
            <li nz-menu-item>Option 2</li>
          </ul>
        </li>
        <li nz-menu-group nzTitle="Item 2">
          <ul>
            <li nz-menu-item>Option 3</li>
            <li nz-menu-item>Option 4</li>
            <li nz-submenu nzTitle="Sub Menu">
              <ul>
                <li nz-menu-item nzDisabled>Option 5</li>
                <li nz-menu-item>Option 6</li>
              </ul>
            </li>
            <li nz-submenu nzDisabled nzTitle="Disabled Sub Menu">
              <ul>
                <li nz-menu-item>Option 5</li>
                <li nz-menu-item>Option 6</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li nz-menu-item>
      <a href="https://ng.ant.design" target="_blank" rel="noopener noreferrer">Navigation Four - Link</a>
    </li>
  </ul>
</div>
<h5 class="mb-3">
  <strong>Vertical</strong>
</h5>
<div class="mb-5">
  <ul nz-menu [nzMode]="'vertical'" style="width: 240px;">
    <li nz-submenu nzTitle="Navigation One" nzIcon="mail">
      <ul>
        <li nz-menu-group nzTitle="Item 1">
          <ul>
            <li nz-menu-item>Option 1</li>
            <li nz-menu-item>Option 2</li>
          </ul>
        </li>
        <li nz-menu-group nzTitle="Item 2">
          <ul>
            <li nz-menu-item>Option 3</li>
            <li nz-menu-item>Option 4</li>
          </ul>
        </li>
      </ul>
    </li>
    <li nz-submenu nzTitle="Navigation Two" nzIcon="appstore">
      <ul>
        <li nz-menu-item>Option 5</li>
        <li nz-menu-item>Option 6</li>
        <li nz-submenu nzTitle="Submenu">
          <ul>
            <li nz-menu-item>Option 7</li>
            <li nz-menu-item>Option 8</li>
          </ul>
        </li>
      </ul>
    </li>
    <li nz-submenu nzTitle="Navigation Three" nzIcon="setting">
      <ul>
        <li nz-menu-item>Option 9</li>
        <li nz-menu-item>Option 10</li>
        <li nz-menu-item>Option 11</li>
      </ul>
    </li>
  </ul>
</div>