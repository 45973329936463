<h5 class="mb-3">
  <strong>Pagination Usage</strong>
</h5>
<div class="mb-5">
  <nz-pagination [nzPageIndex]="1" [nzTotal]="50"></nz-pagination>
</div>
<div class="mb-5">
  <nz-pagination [nzPageIndex]="1" [nzTotal]="500"></nz-pagination>
</div>
<div class="mb-5">
  <nz-pagination [nzPageIndex]="3" [nzTotal]="500" nzShowSizeChanger [nzPageSize]="10"></nz-pagination>
  <br />
  <nz-pagination [nzPageIndex]="3" [nzTotal]="500" nzShowSizeChanger [nzPageSize]="10" nzDisabled></nz-pagination>
</div>
<div class="mb-5">
  <nz-pagination [(nzPageIndex)]="current" [nzTotal]="50" [nzSize]="'small'"></nz-pagination>
</div>
<div class="mb-5">
  <nz-pagination [(nzPageIndex)]="current" [nzTotal]="50" [nzSize]="'small'" nzShowSizeChanger nzShowQuickJumper>
  </nz-pagination>
</div>
<div class="mb-5">
  <nz-pagination [nzPageIndex]="2" [nzTotal]="50" nzSimple></nz-pagination>
</div>
<div class="mb-5">
  <nz-pagination [(nzPageIndex)]="current" [nzTotal]="50" [nzSize]="'small'" [nzShowTotal]="totalTemplate">
  </nz-pagination>
  <ng-template #totalTemplate let-total>Total {{ total }} items</ng-template>
</div>