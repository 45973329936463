import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-typography-5',
  templateUrl: './5.component.html',
  styleUrls: ['./5.component.scss'],
})
export class VbTypography5Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
