export const getMenuData: any[] = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'Reciters',
    key: 'reciters',
    icon: 'fe fe-home',
    url: `/reciters`,
  },
  {
    title: 'Recitations',
    key: 'recitations',
    icon: 'fe fe-home',
    url: `/recitations`,
  },
  {
    title: 'Recitation-Styles',
    key: 'recitation-styles',
    icon: 'fe fe-home',
    url: `/recitation-styles`,
  },
  {
    title: 'Categories',
    key: 'Badges',
    icon: 'fe fe-home',
    url: `/categories`,
  },
  {
    title: 'Recitations-Statistics',
    key: 'recitations-statistics',
    icon: 'fe fe-home',
    url: `/recitations-statistics`,
  },
  {
    title: 'Admins',
    key: 'admins',
    icon: 'fe fe-home',
    url: `/admins`,
  },
  // VB:REPLACE-END:MENU-CONFIG
]
