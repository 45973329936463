<div class="row">
  <div class="col-lg-12 mb-5">
    <h5 class="mb-4">
      <strong>Default Dropdowns</strong>
    </h5>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>Clear</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-default" id="dropdownBasic2" ngbDropdownToggle>Default</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-primary" id="dropdownBasic3" ngbDropdownToggle>Primary</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-secondary" id="dropdownBasic4" ngbDropdownToggle>Secondary</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-success" id="dropdownBasic5" ngbDropdownToggle>Success</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic5">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-info" id="dropdownBasic6" ngbDropdownToggle>Info</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic6">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-warning" id="dropdownBasic7" ngbDropdownToggle>Warning</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic7">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-danger" id="dropdownBasic8" ngbDropdownToggle>Danger</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic8">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-light" id="dropdownBasic9" ngbDropdownToggle>Light</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic9">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-dark" id="dropdownBasic10" ngbDropdownToggle>Dark</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic10">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <br />
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-default" id="dropdownBasic2" ngbDropdownToggle>Default</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-primary" id="dropdownBasic3" ngbDropdownToggle>Primary</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-secondary" id="dropdownBasic4" ngbDropdownToggle>Secondary</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-success" id="dropdownBasic5" ngbDropdownToggle>Success</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic5">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-info" id="dropdownBasic6" ngbDropdownToggle>Info</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic6">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-warning" id="dropdownBasic7" ngbDropdownToggle>Warning</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic7">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-danger" id="dropdownBasic8" ngbDropdownToggle>Danger</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic8">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-light" id="dropdownBasic9" ngbDropdownToggle>Light</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic9">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-dark" id="dropdownBasic10" ngbDropdownToggle>Dark</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic10">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Something else is here</button>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Alignment</strong>
    </h5>
    <div ngbDropdown placement="bottom-right" class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-default" id="alignment" ngbDropdownToggle>Right Aligned</button>
      <div ngbDropdownMenu aria-labelledby="alignment">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Another Action</button>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Dividers</strong>
    </h5>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-default" id="dividers" ngbDropdownToggle>With dividers</button>
      <div ngbDropdownMenu aria-labelledby="dividers">
        <h6 class="dropdown-header">Header</h6>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Another Action</button>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Headers</strong>
    </h5>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-default" id="headers" ngbDropdownToggle>With headers</button>
      <div ngbDropdownMenu aria-labelledby="headers">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem>Another Action</button>
        <h6 class="dropdown-header">Header</h6>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Icons</strong>
    </h5>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-default" id="icons" ngbDropdownToggle>With icons</button>
      <div ngbDropdownMenu aria-labelledby="icons">
        <button ngbDropdownItem><i class="fe fe-activity mr-2"></i> Reply</button>
        <button ngbDropdownItem><i class="fe fe-clock mr-2"></i> Share</button>
        <button ngbDropdownItem><i class="fe fe-credit-card mr-2"></i> Delete</button>
        <button ngbDropdownItem><i class="fe fe-plus-circle mr-2"></i> Add</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem><i class="fe fe-settings mr-2"></i> Settings</button>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Active Item</strong>
    </h5>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-default" id="active" ngbDropdownToggle>With active item</button>
      <div ngbDropdownMenu aria-labelledby="active">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem class="active">Action</button>
        <button ngbDropdownItem>Another action</button>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Disabled Item</strong>
    </h5>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-default" id="disabled" ngbDropdownToggle>With disabled item</button>
      <div ngbDropdownMenu aria-labelledby="disabled">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem disabled>Another action</button>
        <button ngbDropdownItem disabled>Another</button>
        <button ngbDropdownItem>Enabled</button>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Sizing</strong>
    </h5>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-lg btn-outline-default" id="sizing-large" ngbDropdownToggle>Large</button>
      <div ngbDropdownMenu aria-labelledby="sizing-large">
        <h6 class="dropdown-header">Header</h6>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Another Action</button>
      </div>
    </div>
    <div ngbDropdown class="d-inline-block mb-2 mr-2">
      <button class="btn btn-sm btn-outline-default" id="sizing-small" ngbDropdownToggle>Small</button>
      <div ngbDropdownMenu aria-labelledby="sizing-large">
        <h6 class="dropdown-header">Header</h6>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Another Action</button>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Split Buttons</strong>
    </h5>
    <div class="btn-group mb-2 mr-2">
      <button type="button" class="btn btn-outline-default">Split me</button>
      <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
        <button class="btn btn-outline-default dropdown-toggle-split" ngbDropdownToggle></button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <h6 class="dropdown-header">Header</h6>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem disabled>Action</button>
          <button ngbDropdownItem>Another Action</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem>Another Action</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <h5 class="mb-4">
      <strong>Dropup</strong>
    </h5>
    <div ngbDropdown placement="top-left" class="d-inline-block mb-2 mr-2">
      <button class="btn btn-outline-default" id="alignment" ngbDropdownToggle>Default</button>
      <div ngbDropdownMenu aria-labelledby="alignment">
        <h6 class="dropdown-header">Header</h6>
        <button ngbDropdownItem disabled>Action</button>
        <button ngbDropdownItem>Another Action</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem>Another Action</button>
      </div>
    </div>
  </div>
</div>