import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-toasts-example',
  templateUrl: './toasts.component.html',
})
export class KitBootstrapToastsExampleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
