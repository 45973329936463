<div class="footer">
  <div class="footerInner">
    <a class="logo">
      <img style="width: 50px;  margin: auto;" src="assets/images/logo.png"
           >
      <br/>
      <p class="mb-0">
        Copyright © {{ date }}
        <!--        <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer">-->
        <!--        </a>-->
<!--        |-->
        <!--      <a href="https://sellpixels.com/privacy" target="_blank" rel="noopener noreferrer">-->
        <!--        Privacy Policy-->
        <!--      </a>-->
      </p>
    </a>
  </div>
</div>
