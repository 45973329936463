import { Component } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-buttons-example',
  templateUrl: './buttons.component.html',
})
export class KitBootstrapButtonsExampleComponent {
  model = {
    left: true,
    middle: false,
    right: false,
  }
}
