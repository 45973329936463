<div>
  <div class="textDivider mb-2">
    <h4 class="textDividerContent font-size-24 font-weight-bold">
      Waiting actions
    </h4>
  </div>
  <nz-table #basicTable [nzShowPagination]="false" [nzData]="listOfAllData"
    (nzCurrentPageDataChange)="currentPageDataChange($event)" class="table mb-4">
    <thead>
      <tr>
        <th nzShowCheckbox [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
          (nzCheckedChange)="checkAll($event)"></th>
        <th>User Name</th>
        <th>Location</th>
        <th class="text-right text-gray-6">Value</th>
        <th>Last week profit</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.id]" [nzDisabled]="data.disabled"
          (nzCheckedChange)="refreshStatus()"></td>
        <td>
          <div>
            <div>{{data.userName.name}}</div>
            <div class="text-gray-4">{{data.userName.position}}</div>
          </div>
        </td>
        <td>
          <a href="javascript: void(0);" class="text-blue">
            {{data.location}}
          </a>
        </td>
        <td class="text-right text-gray-6">{{data.value}}</td>
        <td class="text-right">
          <x-chartist [data]="data.chart" [type]="'Line'" [options]="chartOptions" class="ct-hidden-points">
          </x-chartist>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>