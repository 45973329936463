import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { Router } from '@angular/router'
import { AuthService } from '../../modules/authentication/services/auth.service'
import { catchError } from 'rxjs/operators'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.authService.logout()
    }
    return throwError(err)
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignore =
      typeof request.body === 'undefined' ||
      request.body === null ||
      request.body.toString() === '[object FormData]' || // <-- This solves your problem
      request.headers.has('Content-Type')
    if (request.headers.get('skip')) {
      return next.handle(request)
    }
    if (ignore) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': 'none',
          Authorization: `Bearer ${this.authService.getToken()}`,
        },
      })
      return next.handle(request).pipe(catchError(x => this.handleAuthError(x)))
    }
    if (this.authService.getToken()) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Accept-Language': 'none',
          Authorization: `Bearer ${this.authService.getToken()}`,
        },
      })
    } else {
      request = request.clone({})
      return next.handle(request)
    }
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)))
  }
}
