<div>
  <div class="d-flex flex-wrap">
    <div class="flex-grow-1 d-flex flex-column flex-sm-row mb-4">
      <div class="font-size-18 font-weight-bold text-uppercase mb-4">
        <div>From:</div>
        <div class="text-dark mb-3">Amazon delivery</div>
        <img class="d-block" src="assets/images/content/amazon-logo.jpg" alt="Amazon logo" />
      </div>
      <div class="ml-sm-auto mr-lg-auto pr-3">
        795 Folsom Ave, Suite 600
        <br />
        San Francisco, CA, 94107
        <br />
        E-mail: example@amazon.com
        <br />
        Phone: (123) 456-7890
        <br />
        Fax: 800-692-7753
      </div>
    </div>
    <div class="flex-grow-1 d-flex flex-column flex-sm-row mb-4">
      <div class="font-size-18 font-weight-bold text-uppercase pb-4">
        <div>To:</div>
        <div class="text-dark mb-3">Invoice info</div>
        <div class="text-dark">W32567-2352-4756</div>
        <div class="text-dark">Artour Arteezy</div>
      </div>
      <div class="mt-auto mt-sm-0 ml-sm-auto pr-3 mr-lg-auto">
        795 Folsom Ave, Suite 600
        <br />
        San Francisco, CA, 94107
        <br />
        P: (123) 456-7890
        <br />
        Invoice Date: January 20, 2016
        <br />
        Due Date: January 22, 2016
      </div>
    </div>
  </div>
  <div class="table-responsive text-nowrap">
    <nz-table class="vb__utils__table mb-4" #invoiceTable [nzShowPagination]="false" [nzData]="listOfAllData"
      (nzCurrentPageDataChange)="currentPageDataChange($event)">
      <thead>
        <tr>
          <th nzShowCheckbox [(nzChecked)]="isAllDisplayDataChecked" [nzIndeterminate]="isIndeterminate"
            (nzCheckedChange)="checkAll($event)"></th>
          <th>Product</th>
          <th>Location</th>
          <th>Description</th>
          <th class="text-right">Quantity</th>
          <th class="text-right">Unit Cost</th>
          <th class="text-right">Summary</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of invoiceTable.data">
          <td nzShowCheckbox [(nzChecked)]="mapOfCheckedId[data.id]" [nzDisabled]="data.disabled"
            (nzCheckedChange)="refreshStatus()"></td>
          <td>
            {{data.product}}
          </td>
          <td>
            <a href="javascript: void(0);" class="text-blue">
              {{data.location}}
            </a>
          </td>
          <td>{{data.description}}</td>
          <td>
            <div class="font-weight-bold text-dark text-right">{{data.quantity}}</div>
          </td>
          <td>
            <div class="font-weight-bold text-dark text-right">{{data.cost}}</div>
          </td>
          <td>
            <div class="font-weight-bold text-dark text-right">{{data.overall}}</div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  <div class="text-right font-size-18 text-dark p-4 rounded bg-light">
    <div>
      Sub - Total amount: <span class="font-weight-bold">$406,472.50</span>
    </div>
    <div>
      VAT: <span class="font-weight-bold">$81,294.50</span>
    </div>
    <div>
      Grand Total: <span class="font-weight-bold">$487,767.00</span>
    </div>
    <a href="javascript: void(0);" class="btn btn-outline-success mr-3 mt-3">
      Print
    </a>
    <a href="javascript: void(0);" class="btn btn-success mt-3">
      Proceed Payment
    </a>
  </div>
</div>