import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-pagination-example',
  templateUrl: './pagination.component.html',
})
export class KitBootstrapPaginationExampleComponent implements OnInit {
  page = 4

  constructor() {}

  ngOnInit() {}
}
