import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-module'
import { WidgetsComponentsModule } from 'src/app/@vb/widgets/widgets-components.module'

// layouts & notfound
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

// pages
// VB:REPLACE-START:ROUTER-IMPORTS
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { SharedModule } from './shared/shared.module'
import { LayoutAuthComponent } from './layouts/Auth/auth.component'
import { CanActivateViaAuthGuard } from './modules/authentication/auth-guard/auth.guard'

// VB:REPLACE-END:ROUTER-IMPORTS

const routes: Routes = [
  {
    path: '',
    redirectTo: 'reciters',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutMainComponent,
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: 'recitation-styles',
        loadChildren: () =>
          import('src/app/modules/recitation-styles/recitation-styles.module').then(
            m => m.RecitationStylesModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'reciters',
        loadChildren: () =>
          import('src/app/modules/reciters/reciters.module').then(m => m.RecitersModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'recitations',
        loadChildren: () =>
          import('src/app/modules/recitations/recitations.module').then(m => m.RecitationsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('src/app/modules/badges/badges.module').then(m => m.BadgesModule),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'recitations-statistics',
        loadChildren: () =>
          import('src/app/modules/recitations-statistics/recitations-statistics.module').then(
            m => m.RecitationsStatisticsModule,
          ),
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: 'admins',
        loadChildren: () => import('src/app/modules/admins/admins.module').then(m => m.AdminsModule),
        canActivate: [CanActivateViaAuthGuard],
      },
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/authentication/authentication.module').then(
            m => m.AuthenticationModule,
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
    LayoutsModule,
    WidgetsComponentsModule,
  ],
  declarations: [
    // VB:REPLACE-START:ROUTER-DECLARATIONS
    DashboardComponent,

    // VB:REPLACE-END:ROUTER-DECLARATIONS
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
