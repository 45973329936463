import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-general-9',
  templateUrl: './9.component.html',
})
export class VbGeneral9Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
