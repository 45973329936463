<h5 class="mb-3">
  <strong>Basic</strong>
</h5>
<div class="mb-5">
  <div class="mb-3">
    <nz-alert nzType="success" nzMessage="Success Tips" nzShowIcon nzCloseable></nz-alert>
  </div>
  <div class="mb-3">
    <nz-alert nzType="info" nzMessage="Informational Notes" nzShowIcon nzCloseable></nz-alert>
  </div>
  <div class="mb-3">
    <nz-alert nzType="warning" nzMessage="Warning" nzShowIcon nzCloseable></nz-alert>
  </div>
  <div class="mb-3">
    <nz-alert nzType="error" nzMessage="Error" nzShowIcon nzCloseable></nz-alert>
  </div>
</div>
<h5 class="mb-3">
  <strong>Advanced</strong>
</h5>
<div class="mb-5">
  <div class="mb-3">
    <nz-alert nzType="success" nzMessage="Success Tips" nzCloseable
      nzDescription="Detailed description and advices about successful copywriting." nzShowIcon>
    </nz-alert>
  </div>
  <div class="mb-3">
    <nz-alert nzType="info" nzMessage="Informational Notes" nzCloseable
      nzDescription="Additional description and informations about copywriting." nzShowIcon>
    </nz-alert>
  </div>
  <div class="mb-3">
    <nz-alert nzType="warning" nzMessage="Warning" nzCloseable
      nzDescription="This is a warning notice about copywriting." nzShowIcon>
    </nz-alert>
  </div>
  <div class="mb-3">
    <nz-alert nzType="error" nzMessage="Error" nzCloseable nzDescription="This is an error message about copywriting."
      nzShowIcon>
    </nz-alert>
  </div>
</div>