import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-headers-card-header-tabbed-6',
  templateUrl: './card-header-tabbed-6.component.html',
  styleUrls: ['./card-header-tabbed-6.component.scss'],
})
export class VbHeadersCardHeaderTabbed6Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
