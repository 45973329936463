import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-widgets-lists-12',
  templateUrl: './12.component.html',
  styleUrls: ['./12.component.scss'],
})
export class VbList12Component implements OnInit {
  constructor() {}
  ngOnInit() {}
}
