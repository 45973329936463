<nz-layout>
  <nz-content>

    <div class="authContainer" [ngClass]="{
        'vb__layout__squaredBorders': isSquaredBorders,
        'vb__layout__cardsShadow': isCardShadow,
        'vb__layout__borderless': isBorderless,
        'white': authPagesColor === 'white',
        'gray': authPagesColor === 'gray'

      }" [ngStyle]="{backgroundImage: authPagesColor === 'image' ? 'url(assets/images/content/photos/8.jpg)' : ''}">
      <img style="width: 120px;" class="mt-5" src="assets/images/logo.png">
      <div>
        <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
             [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
             [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
