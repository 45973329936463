import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { Router } from '@angular/router'

@Component({
  selector: 'vb-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  name: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''
  userDate: any

  constructor(private store: Store<any>, private router: Router) {
    this.userDate = JSON.parse(localStorage.getItem('userData'))
  }

  logout() {
    localStorage.clear()
    return this.router.navigateByUrl('auth')
  }
}
