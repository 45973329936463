import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'vb-hidden-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss'],
})
export class VbHiddenUtilitiesComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
